.mobile-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa; /* Light background to match home page */
  color: #2c3e50; /* Dark text color */
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  padding: 20px;
  box-sizing: border-box;
}

.mobile-logo {
  width: 100px; /* Adjust the width as necessary */
}

.mobile-message p {
  margin: 0;
  border-radius: 10px;
}
