.sidebar-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: top;
  padding-top: 15px;
  z-index: 10;
}

.sidebar,
.sidebar * {
  -webkit-user-select: none;
  user-select: none;
  
}

.sidebar {
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow:  20px 20px 34px #f0f0f0,
             -20px -20px 34px #ffffff;
  margin: 20px;
  width: 250px;
  height: 94%;
  min-height: 768px;
  opacity: 0.925;
  border-radius: 20px;
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  

  &.closed {
    transform: translateX(-100%);
  }

  .sidebar-header,
  .sidebar-icon,
  .sidebar-nav,
  .sidebar-footer {
    transition: transform 0.6s ease-in-out;
  }

  &.closed .sidebar-header,
  &.closed .sidebar-icon,
  &.closed .sidebar-nav,
  &.closed .sidebar-footer {
    transform: translateX(-250px);
  }

  .sidebar-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }

  .close-sidebar-button {
    background-color: transparent;
    margin: 3px;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #39a265;
      transform: scale(1.012);
    }
  }

  .sidebar-icon {
    display: flex;
    justify-content: center;
    padding: 10px;
    border-bottom: rgba(44, 62, 80, 0.2) 1px solid;
    margin-bottom: 8%;

    .logo-container {
      width: 145px;
      height: 145px;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo-image {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .sidebar-nav {
    display: flex;
    flex-wrap: wrap;
    gap: 17.5px;
    padding: 9px;
    padding-left: 30px;
    max-width: 200px;
    justify-content: center;
  }

  .sidebar-link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 90px;
    height: 40px;
    color: #2c3e50;
    background-color: transparent;
    text-decoration: none;
    border-radius: 10px;
    font-size: 0.85em;
    text-align: center;
    transition: background-color 0.1s, transform 0.3s;

    &.active {
      background-color: #39a265;
      color: #fff;
    }

    &:hover {
      background-color: #39a265;
      color: #fff;
      transform: scale(1.012);
    }
  }

  .sidebar-socials {
    padding: 20px;
    text-align: center;

    .social-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;

      .icon-content {
        margin: 0 7.5px; /* Adjusted margin to fit within 80% width */
        position: relative;

        a {
          position: relative;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px; /* Smaller size */
          height: 35px; /* Smaller size */
          border-radius: 50%;
          color: #4d4d4d;
          background-color: #fff;
          transition: all 0.3s ease-in-out;

          &:hover {
            box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
            color: white;
          }

          svg {
            position: relative;
            z-index: 1;
            width: 17.5px; /* Smaller icon size */
            height: 17.5px; /* Smaller icon size */
          }
        }

        .tooltip {
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
          padding: 6px 10px;
          border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          font-size: 10px; /* Smaller tooltip text */
          transition: all 0.3s ease;

          &:hover {
            opacity: 1;
            visibility: visible;
            top: -50px;
          }
        }
      }
    }
  }

  .sidebar-acknowledgements {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;

    h2 {
      margin-bottom: 15px;
      font-size: 0.85em;
      color: #2c3e50;
    }

    .acknowledgement-single {

      h3 {
        margin-bottom: 10px;
        font-size: 0.85em;
        color: #2c3e50;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 5px;
          font-size: 0.8em;
          color: #2c3e50;
        }
      }
    }

    .acknowledgement-section {
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .sidebar-footer {
    margin-top: auto;
    padding: 20px;
    text-align: center;

    .github-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.8em;
      color: #2c3e50;
      text-decoration: none;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      &:hover {
        text-decoration: underline;
        color: #39a265;
      }
    }
  }
}

.open-sidebar-button {
  background-color: transparent;
  margin: 3px;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 25%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 25px;
  left: -50px;
  transition: left 0.3s ease-in-out;
  z-index: 1000;

  &:hover {
    background-color: #f8f9fa;
    transform: scale(1.012);
  }

  &.visible {
    left: 25px;
    transition: left 0.8s ease-in-out;
  }
}

.copyright {
  font-size: 0.7em;
  color: #2c3e50;
  margin-top: -18px;
  padding-bottom: 15px;
  text-align: center; /* Center the text */
  z-index: 3;
}

.copyright a {
  color: #2c3e50;
  text-decoration: none;
  display: block; /* Ensure the link starts on a new line */
  margin-top: 5px; /* Add some space above the link */
}

.copyright a:hover {
  text-decoration: underline; /* Add an underline on hover for better visibility */
  color: #39a265; /* Change the color on hover */
  cursor: pointer;
}