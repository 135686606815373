// Loader.scss
.loader {
  position: relative;
  width: 1.5em;
  aspect-ratio: 1/2;
  filter: url(#goo);
}

#goo {
  display: none;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100%;
  aspect-ratio: 1/1;
  animation: 3s ease-out infinite loader;
  border-radius: 50%;
}

.loader:before {
  background: linear-gradient(#39a265, rgb(66, 181, 73));
}

.loader:after {
  animation-delay: 1.5s;
  background: linear-gradient(to top, #39a265, rgb(66, 181, 73));
}

@keyframes loader {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(90%);
  }

  75% {
    transform: translateY(-90%);
  }
}
