.home-page {
  top: 0;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  border-radius: 20px;
  overflow: visible;
  color: #2c3e50;
  height: 90vh;

  .background-image {
    position: absolute;
    top: -10px;
    right: -4px;
    box-shadow: 0 0 20px 20px white inset;
    height: 400px;
    width: auto;
    opacity: 0.5;
    z-index: 0;
  }
}

.header {
  top: 0;
  left: 0;
  padding: 25px 20px;
  width: 100%;
  height: 27.5%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  -webkit-user-select: none;
  user-select: none;

  &:before {
    content: "";
    background: url("../assets/images/left-quote.svg") no-repeat;
    background-size: 80px 80px;
    position: absolute;
    top: 50px;
    left: 0px;
    width: 80px;
    height: 80px;
    opacity: 0.15;
  }

  .title {
    font-weight: 400;
    margin: 0;
    padding: 20px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width: 70%;
    z-index: 1;

    font-size: 60px;

    @media (min-width: 992px) and (min-height: 992px) {
      font-size: 75px;
    }

    @media (min-width: 1200px) and (min-height: 1200px) {
      font-size: 90px;
    }
  }

  .eco-text {
    color: #39a265;
  }

  .eco-image {
    height: 1em;
    margin: -12px 20px;
    border-radius: 50%;
  }

  .try-button-container {
    display: flex;
    align-items: end;
    justify-content: center;
    padding-bottom: 30px;
    padding-left: 55px;
    z-index: 1;
  }
}

.middle {
  height: 42.5%;
}

.animated-text-container {
  width: calc(100% - 40px);
  padding: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.description-contact {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 30%;
  gap: 20px;
  bottom: 0;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0;
  box-sizing: border-box;
  border-top: #2c3e50 solid 2px;

  .project-description,
  .contact-form {
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative; // Add position relative to parent
  }

  .project-description {
    flex: 3;
    display: flex;
    flex-direction: column;

    h3 {
      top: 10px; // Adjust the top value as needed
      left: 20px;
      margin: 0;

      @media (min-width: 768px) and (min-height: 768px) {
        font-size: 15px;
      }

      @media (min-width: 992px) and (min-height: 992px) {
        font-size: 18px;
      }

      @media (min-width: 1200px) and (min-height: 1200px) {
        font-size: 20px;
      }
    }

    .description-text {
      color: #2c3e50;
      text-align: left;
      margin-top: 3%; // Adjust margin-top to provide space for h3

      font-size: 15px;

      @media (min-width: 1200px) and (min-height: 1200px) {
        font-size: 18px;
      }
    }
  }

  .contact-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
      top: 10px; // Adjust the top value as needed
      left: 20px;
      margin: 0;
    }

    form {
      margin-top: 11%;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      input,
      textarea {
        color: #2c3e50;
        font-family: "Poppins", sans-serif;
        margin-bottom: 10px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        border-radius: 12px;
        font-size: 12px; /* Base font size */
        outline: none;

        @media (min-width: 1200px) and (min-height: 1200px) {
          font-size: 15px;
        }
      }

      textarea {
        height: 50%;
        resize: none;
      }

      button {
        padding: 5px 10px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.1s, transform 0.3s;
        font-family: "Poppins", sans-serif;

        font-size: 12px;

        &:hover {
          background-color: #39a265;
          color: #fff;
          transform: scale(1.012);
        }
      }
    }
  }
}

.centered-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  text-align: center;

}

.try-button {
  position: relative;
  padding: 10px 20px;
  font-size: 22px; /* Moved the declaration here */
  font-weight: bold;
  cursor: pointer;
  background: none;
  color: #2c3e50;
  border-radius: 15px;
  margin-top: 0;
  z-index: 1;
  text-decoration: none;
  display: flex;
  align-items: center;

  .enter-icon {
    margin-left: 10px;
  }

  &:before {
    content: none;
  }

  &:hover {
    background-color: rgba(240, 240, 240, 0.5);
  }

  @media (min-width: 992px) and (min-height: 992px) {
    font-size: 24px;
  }

  @media (min-width: 1200px) and (min-height: 1200px) {
    font-size: 26px;
  }
}


